import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import Home from '../components/Home';

const HomeRouter = (props) => (
  <Router>
    <Home {...props} path="/home" />
    <NotFoundPage default />
  </Router>
);

export default HomeRouter;
